import React from 'react';
import Icon from './icon';

const FileList = ({ files, onRemoveFile, compact = false }) => {
  if (!files.length) return null;

  return (
    <div className={`uploaded-files-list ${compact ? 'compact' : ''}`}>
      <div className="files-row">
        {files.map((file, index) => (
          <div key={index} className="file-badge">
            <Icon name="document" className="file-icon" />
            <span className="file-name" title={file.filename}>
              {file.filename.length > 15 
                ? file.filename.substring(0, 12) + '...' 
                : file.filename}
            </span>
            <button 
              onClick={() => onRemoveFile(file.filepath)}
              className="remove-badge-btn"
              aria-label="Remove file"
            >
              <Icon name="delete" className="remove-icon" />
            </button>
          </div>
        ))}
      </div>
      <div className="files-counter">
        {files.length}/5 Dokumente
      </div>
    </div>
  );
};

export default FileList;