import React from 'react';
import { ReactComponent as PaperclipIcon } from '../assets/icons/paperclip.svg';
import { ReactComponent as ArrowUpIcon } from '../assets/icons/arrowup.svg';
import { ReactComponent as MediaIcon } from '../assets/icons/photo-film-solid.svg';
import { ReactComponent as doctorMessageIcon } from '../assets/icons/doctor-message.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactComponent as QuestionIcon } from '../assets/icons/question-solid.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete_x.svg'

const iconTypes = {
  paperclip: PaperclipIcon,
  arrowUp: ArrowUpIcon,
  media: MediaIcon,
  doctorMessage: doctorMessageIcon,
  logout: LogoutIcon,
  question: QuestionIcon,
  delete: DeleteIcon
};

const Icon = ({ name, ...props }) => {
  const IconComponent = iconTypes[name];
  return IconComponent ? <IconComponent {...props} /> : null;
};

export default Icon;