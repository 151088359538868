import React from 'react';

const DisclaimerPopup = ({ onClose }) => {
  return (
    <div className="disclaimer-overlay">
      <div className="disclaimer-popup">
        <h2>Wichtiger Hinweis</h2>
        <div className="disclaimer-content">
            <p>Die Chat-Funktion und die medizinische Analyse dienen ausschließlich Testzwecken.</p>
            <p>Die von der KI erstellten Diagnosen können fehlerhaft sein und stellen keine medizinische Beratung dar.</p>
        </div>
        <button onClick={onClose} className="disclaimer-confirm-btn">
          Alles klar
        </button>
      </div>
    </div>
  );
};

export default DisclaimerPopup;