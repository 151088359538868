import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';

function PasswordInput({ id, value, onChange, disabled, placeholder }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="input-wrapper">
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        value={value}
        onChange={onChange}
        required
        disabled={disabled}
        placeholder={placeholder}
        className="auth-input"
      />
      <button
        type="button"
        onClick={togglePasswordVisibility}
        className="icon-button password-toggle-btn"
        disabled={disabled}
      >
        {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
      </button>
    </div>
  );
}

export default PasswordInput;