import React, { useState, useEffect } from 'react';
import Icon from './icon'

function AdminPanel({ onLogout }) {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: '', password: '', is_admin: false });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/admin/users', { credentials: 'include' });
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        setError('Failed to fetch users');
      }
    } catch (error) {
      setError('Error fetching users');
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/admin/users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUser),
        credentials: 'include'
      });
      if (response.ok) {
        setSuccess('User created successfully');
        setNewUser({ email: '', password: '', is_admin: false });
        fetchUsers();
      } else {
        setError('Failed to create user');
      }
    } catch (error) {
      setError('Error creating user');
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await fetch(`/api/admin/users/${userId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (response.ok) {
        setSuccess('User deleted successfully');
        fetchUsers();
      } else {
        setError('Failed to delete user');
      }
    } catch (error) {
      setError('Error deleting user');
    }
  };

  return (
    <div className="admin-panel">
       <header className="admin-header">
        <h1>Admin Panel</h1>
        <div className="tooltip tooltip-bottom">
          <button onClick={onLogout} className="logout-btn" aria-label="Logout">
            <Icon name="logout" className="logout-icon" />
          </button>
          <span className="tooltiptext">Abmelden</span>
        </div>
      </header>
      <div className="admin-content">
        <section className="create-user-section">
          <h2>Create New User</h2>
          <form onSubmit={createUser}>
            <input
              type="email"
              value={newUser.email}
              onChange={(e) => setNewUser({...newUser, email: e.target.value})}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({...newUser, password: e.target.value})}
              placeholder="Password"
              required
            />
            <label>
              <input
                type="checkbox"
                checked={newUser.is_admin}
                onChange={(e) => setNewUser({...newUser, is_admin: e.target.checked})}
              />
              Is Admin
            </label>
            <button className="createuser" type="submit">Create User</button>
          </form>
        </section>
        <section className="user-list-section">
          <h2>User List</h2>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="success-message">{success}</p>}
          <ul>
            {users.map(user => (
              <li key={user.id} className="user-item">
                <span>{user.email} - {user.is_admin ? 'Admin' : 'User'}</span>
                <button onClick={() => deleteUser(user.id)} className="delete-btn">Delete</button>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
}

export default AdminPanel;