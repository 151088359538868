import React from 'react';

const FloatingTooltip = ({ text, x, y }) => {
  return (
    <div 
      className="floating-tooltip" 
      style={{ 
        position: 'fixed', 
        left: `${x}px`, 
        top: `${y}px`, 
        zIndex: 1000 
      }}
    >
      {text}
    </div>
  );
};

export default FloatingTooltip;