// PrivacyPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import fullLogo from './logo512.png';

const PrivacyPage = () => {
  const navigate = useNavigate();

const handleBack = () => {
  navigate('/');
};

return (
  <div className="privacy-page">
    <button 
      className="privacy-back-button"
      onClick={handleBack}
    >
      Zurück
    </button>

    <div className="privacy-logo-container">
      <img 
        src={fullLogo} 
        alt="Logo" 
        className="privacy-logo"
        onClick={handleBack}
      />
    </div>

      {/* Content Container */}
      <div className="privacy-page-content">
        {/* Impressum Section */}
        <div className="impressum-section">
          <h1>Impressum</h1>
          <div className="impressum-content">
            <p>Julian Schmidt</p>
            <p>Münchener Straße 6</p>
            <p>10775 Berlin</p>
            <p>Deutschland</p>
            <p>&nbsp;</p>
            <p>
              <a href="mailto:julianschmidt@neurix.de" className="text-blue-400 hover:underline">
                julianschmidt@neurix.de
              </a>
            </p>
          </div>
          <p className="impressum-note">
            Zur Teilnahme an einem Streitbeilegungsverfahren vor einer 
            Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.
          </p>
        </div>

      {/* Privacy Policy Section */}
      <div className="privacy-header">
        <h1 className="main-title">Datenschutzerklärung</h1>
        <em className="date">Stand: 1. März 2024</em>
      </div>

        {/* Main Content */}
        <div className="privacy-sections">
          {/* Vorwort */}
          <section>
            <h2>Vorwort: Geltungsbereich dieser Datenschutzerklärung</h2>
            <p>Diese Datenschutzerklärung unterscheidet zwei Verarbeitungssituationen:</p>
              <li>
                Die Verarbeitung von Daten durch uns als Verantwortlicher:
                Dies betrifft die Nutzung unserer Website und die damit verbundenen Services. 
                Hierfür gelten die Abschnitte I-VIII dieser Erklärung.
              </li>
              <li>
                Die Verarbeitung von Patientendaten als Auftragsverarbeiter:
                Wenn Ärzte und medizinische Einrichtungen unsere Software zur Verarbeitung 
                von Patientendaten nutzen, sind wir Auftragsverarbeiter im Sinne des Art. 28 DSGVO. 
                Der jeweilige Arzt bzw. die medizinische Einrichtung ist dabei Verantwortlicher. 
                Diese Konstellation wird in Abschnitt IV A gesondert geregelt.
              </li>
          </section>

          {/* Section I */}
          <section>
            <h2>I. Name und Anschrift des Verantwortlichen</h2>
            <p>
              Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer 
              nationaler Datenschutzgesetze der Mitgliedsstaaten sowie sonstiger 
              datenschutzrechtlicher Bestimmungen ist die:
            </p>
            <div className="text-center space-y-4">
              <p>Julian Schmidt</p>
              <p>Münchener Straße 6, 10779 Berlin, Deutschland</p>
              <p>
                <span className="font-bold">E-Mail: </span>
                <a href="mailto:support@neurix.de" className="text-blue-400 hover:underline">
                  support@neurix.de
                </a>
              </p>
              <p>
                <span className="font-bold">Website: </span>
                <a href="https://neurix.de" className="text-blue-400 hover:underline">
                  https://neurix.de
                </a>
              </p>
            </div>
          </section>

          {/* Section II */}
          <section>
            <h2>II. Name und Anschrift des Datenschutzbeauftragten</h2>
            <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
            <div className="text-center space-y-4">
              <p>Julian Schmidt</p>
              <p>
                <span className="font-bold">E-Mail: </span>
                <a href="mailto:julianschmidt@neurix.de" className="text-blue-400 hover:underline">
                  julianschmidt@neurix.de
                </a>
              </p>
            </div>
          </section>

{/* Section III */}
<section>
            <h2>III. Allgemeines zur Datenverarbeitung</h2>
            
            <div>
              <div>
                <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
                <p>
                  Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit
                  dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und
                  Leistungen erforderlich ist. Die Verarbeitung personenbezogener Daten unserer
                  Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt
                  in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus
                  tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch
                  gesetzliche Vorschriften gestattet ist.
                </p>
              </div>

              <div>
                <h3>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
                <p>
                  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung
                  der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung
                  (DSGVO) als Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten, die
                  zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist,
                  erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt
                  auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
                  erforderlich sind. [...]
                </p>
              </div>

              <div>
                <h3>3. Datenlöschung und Speicherdauer</h3>
                <p>
                  Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt,
                  sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus
                  erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in
                  unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
                  Verantwortliche unterliegt, vorgesehen wurde. [...]
                </p>
              </div>
            </div>
          </section>

          {/* Section IV */}
          <section>
            <h2>IV. Bereitstellung der Plattform und Erstellung von Logfiles</h2>
            
            <div>
              <div>
                <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
                <p>
                  Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten
                  und Informationen vom Computersystem des aufrufenden Rechners. Folgende Daten
                  werden hierbei erhoben:
                </p>
                  <li>Informationen über den Browsertyp und die verwendete Version</li>
                  <li>das Betriebssystem des Nutzers</li>
                  <li>den Internet-Service-Provider des Nutzers</li>
                  <li>die IP-Adresse des Nutzers</li>
                  <li>Datum und Uhrzeit des Zugriffs</li>
                  <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
                  <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
              </div>

              <div>
                <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
                <p>
                  Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist
                  Art. 6 Abs. 1 lit. f DSGVO.
                </p>
              </div>

              <div>
                <h3>3. Zweck der Datenverarbeitung</h3>
                <p>
                Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns die Daten zur technischen Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt. In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
                </p>
              </div>

              <div>
                <h3>4. Dauer der Speicherung</h3>
                <p>
                Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
                </p>
              </div>

              <div>
                <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
                <p>
                  Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der
                  Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich.
                  Es besteht folglich seitens des Nutzers keine Widerspruchsmöglichkeit.
                </p>
              </div>
            </div>
          </section>

{/* Note about Auftragsverarbeitung */}
<section>
            <p>
              <strong>Hinweis zur Auftragsverarbeitung:</strong><br/>
              Die vorstehenden Regelungen betreffen die Datenverarbeitung auf unserer Website. 
              Für die Verarbeitung von Patientendaten im Rahmen der Nutzung unserer Software durch 
              medizinische Einrichtungen gelten die besonderen Bestimmungen des folgenden 
              Abschnitts IV A.
            </p>
          </section>

          {/* Section IV A */}
          <section>
            <h2>IV A. Auftragsverarbeitung von Patientendaten</h2>
            
            <div>
              <div>
                <h3>1. Beschreibung und Umfang der Auftragsverarbeitung</h3>
                <p>
                  Bei der Nutzung unserer Software durch medizinische Einrichtungen verarbeiten wir 
                  personenbezogene Daten ausschließlich im Auftrag und nach Weisung des jeweiligen 
                  Verantwortlichen. Folgende Daten werden dabei verarbeitet:
                </p>
                
                <div>
                  <div>
                    <h4>a. Verarbeitete Datenkategorien</h4>
                    <ul>
                      <li>- Patientenstammdaten (pseudonymisiert)</li>
                      <li>- Medizinische Befunde und Diagnosen</li>
                      <li>- Behandlungs- und Verlaufsdaten</li>
                      <li>- Medizinische Bild- und Videodaten</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>b. Betroffene Personen</h4>
                    <ul>
                      <li>- Patienten der medizinischen Einrichtung</li>
                      <li>- Behandelndes medizinisches Personal</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <h3>2. Rechtsgrundlage der Auftragsverarbeitung</h3>
                <p>Die Verarbeitung erfolgt auf Grundlage:</p>
                <ul>
                  <li>- eines Auftragsverarbeitungsvertrags nach Art. 28 DSGVO</li>
                  <li>- dokumentierter Weisungen des Verantwortlichen</li>
                  <li>- zusätzlicher Vereinbarungen für Drittlandübermittlungen</li>
                </ul>
              </div>

              <div>
                <h3>3. Zweck und Art der Verarbeitung</h3>
                <p>Die Verarbeitung dient ausschließlich den vom Verantwortlichen festgelegten Zwecken:</p>
                <ul>
                  <li>- Analyse medizinischer Daten mittels KI</li>
                  <li>- Erstellung medizinischer Berichte</li>
                  <li>- Strukturierung medizinischer Dokumentation</li>
                  <li>- Unterstützung bei der Befundung</li>
                </ul>
              </div>

              <div>
                <h3>4. Dauer der Verarbeitung</h3>
                <p>Die Dauer der Verarbeitung richtet sich nach den Weisungen des Verantwortlichen:</p>
                
                <div>
                  <div>
                    <h4>a. Regelmäßige Verarbeitung</h4>
                    <ul>
                      <li>- Sofortige Löschung nach Analyse</li>
                      <li>- Keine dauerhafte Speicherung der Originaldaten</li>
                      <li>- Protokollierung nach gesetzlichen Vorgaben</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>b. Vertragsende</h4>
                    <ul>
                      <li>- Rückgabe aller Daten an den Verantwortlichen</li>
                      <li>- Löschung nach Weisung des Verantwortlichen</li>
                      <li>- Nachweis der Löschung</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <h3>5. Technische und organisatorische Maßnahmen</h3>
                <p>Als Auftragsverarbeiter gewährleisten wir folgende Schutzmaßnahmen:</p>
                
                <div>
                  <div>
                    <h4>a. Vertraulichkeit</h4>
                    <ul>
                      <li>- Ende-zu-Ende-Verschlüsselung</li>
                      <li>Zugriffskontrolle und -protokollierung</li>
                      <li>Mandantentrennung</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>b. Integrität</h4>
                    <ul>
                      <li>- Verschlüsselte Datenübertragung</li>
                      <li>- Integritätsprüfungen</li>
                      <li>- Protokollierung aller Verarbeitungen</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>c. Verfügbarkeit</h4>
                    <ul>
                      <li>- Redundante Systeme</li>
                      <li>- Regelmäßige Backups</li>
                      <li>- Notfallkonzept</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <h3>6. Einsatz von Unterauftragsverarbeitern (KI-Dienste)</h3>
                <p>Für die Analyse medizinischer Daten setzen wir folgende KI-Dienste ein:</p>
                
                <div>
                  <div>
                    <h4>a. Eingesetzte Dienste</h4>
                    <ul>
                      <li>- Anthropic (Claude AI)</li>
                      <li>- OpenAI</li>
                      <li>- Google (Gemini)</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>b. Schutzmaßnahmen</h4>
                    <ul>
                      <li>- Standardvertragsklauseln für Drittlandtransfer</li>
                      <li>- Zusätzliche technische Schutzmaßnahmen</li>
                      <li>- Pseudonymisierung vor Übermittlung</li>
                      <li>- Keine Speicherung durch KI-Dienste</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <h3>7. Pflichten und Verantwortlichkeiten</h3>
                
                <div>
                  <div>
                    <h4>a. Pflichten des Verantwortlichen</h4>
                    <ul>
                      <li>- Rechtmäßigkeit der Verarbeitung sicherstellen</li>
                      <li>- Einwilligungen der Patienten einholen</li>
                      <li>- Weisungen dokumentiert erteilen</li>
                      <li>- Führung des Verarbeitungsverzeichnisses</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h4>b. Unsere Pflichten als Auftragsverarbeiter</h4>
                    <ul>
                      <li>- Weisungsgebundene Verarbeitung</li>
                      <li>- Vertraulichkeit der Mitarbeiter</li>
                      <li>- Unterstützung bei Betroffenenrechten</li>
                      <li>- Unterstützung bei Datenschutzverletzungen</li>
                      <li>- Löschung nach Auftragsende</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

{/* Section V */}
          <section>
            <h2>V. Erfassung von Cookies</h2>
            
            <div>
              <div>
                <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
                <p>
                  Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien,
                  die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem des
                  Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie
                  auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält
                  eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
                  Browsers beim erneuten Aufrufen der Website ermöglicht. 
                </p>
                <p>
                  Wir setzen Cookies ein, um unsere Website funktionsfähig zu gestalten. Einige 
                  Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch 
                  nach einem Seitenwechsel identifiziert werden kann.
                </p>
                <p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>
                <ul>
                  <li>- Einstellungen</li>
                  <li>- Log-In-Informationen</li>
                </ul>
              </div>

              <div>
                <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
                <p>
                  Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung
                  von technisch erforderlichen Cookies i.S.d. § 25 Abs. 2 TTDSG ist Art. 6 Abs. 1
                  lit. f DSGVO.
                </p>
              </div>

              <div>
                <h3>3. Zweck der Datenverarbeitung</h3>
                <p>
                  Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von
                  Websites für die Nutzer zu ermöglichen. Einige Funktionen unserer Internetseite
                  können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es
                  erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.
                </p>
                <p>Für folgende Anwendungen benötigen wir Cookies:</p>
                <ul>
                  <li>- Log-In Informationen</li>
                  <li>- Übernahme von Einstellungen</li>
                </ul>
              </div>

              <div>
                <h3>4. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</h3>
                <p>
                  Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unsere
                  Seite übermittelt. Daher haben Sie als Nutzer auch die volle Kontrolle über die
                  Verwendung von Cookies. Durch eine Änderung der Einstellungen in Ihrem
                  Internetbrowser können Sie die Speicherung von Cookies deaktivieren oder
                  einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies
                  kann auch automatisiert erfolgen.
                </p>
                <p>
                  Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr 
                  alle Funktionen der Website vollumfänglich genutzt werden.
                </p>
                <p className="italic">
                  Hinweis: Bei der Nutzung unserer Software durch medizinische Einrichtungen 
                  werden keine Patientendaten in Cookies gespeichert.
                </p>
              </div>
            </div>
          </section>

          {/* Section VI */}
          <section>
            <h2>VI. Registrierung</h2>
            
            <div>
              <div>
                <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
                <p>
                  Auf unserer Internetseite bieten wir Nutzern die Möglichkeit, sich unter Angabe
                  personenbezogener Daten zu registrieren. Die Daten werden dabei in eine Eingabemaske
                  eingegeben und an uns übermittelt und gespeichert. Eine Weitergabe der Daten an
                  Dritte findet nicht statt.
                </p>
                <p>Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben:</p>
                <ul>
                  <li>- IP-Adresse des Nutzers</li>
                  <li>- Datum und Uhrzeit der Registrierung</li>
                  <li>- Vollständiger Name</li>
                  <li>- Anschrift</li>
                  <li>- Staatsangehörigkeit</li>
                  <li>- E-Mail-Adresse</li>
                  <li>- Evtl. Telefonnummer</li>
                </ul>
              </div>

              <div>
                <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
                <p>
                  Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung
                  des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Da die Registrierung der Erfüllung eines
                  Vertrages mit dem Nutzer dient oder zur Durchführung vorvertraglicher Maßnahmen
                  erforderlich ist, ist zusätzliche Rechtsgrundlage für die Verarbeitung der Daten
                  Art. 6 Abs. 1 lit. b DSGVO.
                </p>
              </div>

              <div>
                <h3>3. Zweck der Datenverarbeitung</h3>
                <p>
                  Eine Registrierung des Nutzers ist für das Bereithalten bestimmter Inhalte und
                  Leistungen auf unserer Website erforderlich. Der Nutzer kann sich jederzeit registrieren.
                  Die im Rahmen der Registrierung eingegebenen Daten verwenden wir nur zum Zwecke der
                  Nutzung des jeweiligen Angebotes oder Dienstes.
                </p>
              </div>

              <div>
                <h3>4. Dauer der Speicherung</h3>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung
                  nicht mehr erforderlich sind. Dies ist für die während des Registrierungsvorgangs
                  erhobenen Daten der Fall, wenn die Registrierung auf unserer Internetseite
                  aufgehoben oder abgeändert wird.
                </p>
              </div>

              <div>
                <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
                <p>
                  Als Nutzer haben Sie jederzeit die Möglichkeit, die Registrierung aufzulösen.
                  Die über Sie gespeicherten Daten können Sie jederzeit abändern lassen.
                  Hierzu können Sie sich per E-Mail an uns wenden.
                </p>
              </div>
            </div>
          </section>

          {/* Section VII */}
          <section>
            <h2>VII. Kontaktformular und E-Mail-Kontakt</h2>
            
            <div>
              <div>
                <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
                <p>
                  Auf unserer Internetseite ist ein Kontaktlink vorhanden, welches für die elektronische 
                  Kontaktaufnahme genutzt werden kann. Nimmt ein Nutzer diese Möglichkeit wahr, so 
                  werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers 
                  gespeichert. Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an 
                  Dritte. Die Daten werden ausschließlich für die Verarbeitung der Konversation 
                  verwendet.
                </p>
              </div>

              <div>
                <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
                <p>
                  Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer 
                  Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO. Rechtsgrundlage für die 
                  Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt 
                  werden, ist Art. 6 Abs. 1 lit. f DSGVO. Zielt der E-Mail-Kontakt auf den 
                  Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die 
                  Verarbeitung Art. 6 Abs. 1 lit. b DSGVO.
                </p>
              </div>

              <div>
                <h3>3. Zweck der Datenverarbeitung</h3>
                <p>
                  Im Falle einer Kontaktaufnahme per E-Mail liegt das berechtigte Interesse an der 
                  Verarbeitung der Daten zum Zwecke der Kontaktaufnahme. Die sonstigen während des 
                  Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen 
                  Missbrauch des Kontaktformulars zu verhindern und die Sicherheit unserer 
                  informationstechnischen Systeme sicherzustellen.
                </p>
              </div>

              <div>
                <h3>4. Dauer der Speicherung</h3>
                <p>
                  Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer 
                  Erhebung nicht mehr erforderlich sind. Für die personenbezogenen Daten, die per 
                  E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation 
                  mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den 
                  Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt 
                  ist. Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen 
                  Daten werden spätestens nach einer Frist von sieben Tagen gelöscht.
                </p>
              </div>

              <div>
                <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
                <p>
                  Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung 
                  der personenbezogenen Daten zu widerrufen. Nimmt der Nutzer per E-Mail Kontakt 
                  mit uns auf, so kann er der Speicherung seiner personenbezogenen Daten jederzeit 
                  widersprechen. In einem solchen Fall kann die Konversation nicht fortgeführt werden.
                </p>
                <p>
                  Zu diesem Zwecke wenden Sie sich bitte per E-Mail an account@tradecompanion.de. 
                  Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, 
                  werden in diesem Fall gelöscht.
                </p>
              </div>
            </div>
          </section>

          {/* Section VIII */}
          <section>
            <h2>VIII. Rechte der betroffenen Personen</h2>
            
            <div>
              <p>
                Wichtiger Hinweis: Wenn Sie Patient einer medizinischen Einrichtung sind, die 
                unsere Software nutzt, richten Sie Ihre Anfragen bitte direkt an diese Einrichtung 
                als Verantwortlichen. Wir werden die Einrichtung bei der Erfüllung Ihrer Rechte im 
                Rahmen unserer Pflichten als Auftragsverarbeiter unterstützen.
              </p>

              <p>
                Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. 
                DSGVO und es stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
              </p>

              <div>
                <h3>1. Auskunftsrecht</h3>
                <p>
                  Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob 
                  personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Liegt 
                  eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende 
                  Informationen Auskunft verlangen:
                </p>
                <ul>
                  <li>- die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
                  <li>- die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
                  <li>- die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;</li>
                  <li>- die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten;</li>
                  <li>- das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten;</li>
                  <li>- das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
                  <li>- wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen über die Herkunft der Daten;</li>
                  <li>- das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling.</li>
                </ul>
              </div>

              <div>
                <h3>2. Recht auf Berichtigung</h3>
                <p>
                  Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem 
                  Verantwortlichen, sofern die verarbeiteten personenbezogenen Daten, die Sie 
                  betreffen, unrichtig oder unvollständig sind. Der Verantwortliche hat die 
                  Berichtigung unverzüglich vorzunehmen.
                </p>
              </div>

              <div>
                <h3>3. Recht auf Einschränkung der Verarbeitung</h3>
                <p>
                  Unter folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der 
                  Sie betreffenden personenbezogenen Daten verlangen:
                </p>
                <ul>
                  <li>- wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten bestreiten;</li>
                  <li>- wenn die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen;</li>
                  <li>- wenn der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt;</li>
                  <li>- wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben.</li>
                </ul>
              </div>

              <div>
                <h3>4. Recht auf Löschung</h3>
                <div>
                  <h4>a. Löschungspflicht</h4>
                  <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:</p>
                    <li>Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.</li>
                    <li>Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.</li>
                    <li>Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.</li>
                    <li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
                    <li>Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche unterliegt.</li>
                    <li>Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.</li>

                  <h4>b. Information an Dritte</h4>
                  <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>

                  <h4>c. Ausnahmen</h4>
                  <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
                    <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                    <li>zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;</li>
                    <li>aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;</li>
                    <li>für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das unter Abschnitt a. genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder</li>
                    <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
                </div>
              </div>

              <div>
                <h3>5. Recht auf Unterrichtung</h3>
                <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
              </div>

              <div>
                <h3>6. Recht auf Datenübertragbarkeit</h3>
                <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
                  <li>die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und</li>
                  <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
                <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht beeinträchtigt werden.</p>
                <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
              </div>

              <div>
                <h3>7. Widerspruchsrecht</h3>
                <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
                <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</p>
                <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
                <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.</p>
              </div>

              <div>
                <h3>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
                <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
              </div>

              <div>
                <h3>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
                <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>
                  <li>für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich ist,</li>
                  <li>aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder</li>
                  <li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
                <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen wurden.</p>
                <p>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</p>
              </div>

              <div>
                <h3>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
                <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.</p>
                <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;