import React, { memo } from 'react';
import trashIcon from '../assets/icons/trash-solid.svg';
import viewIcon from '../assets/icons/view.svg';
import saveIcon from '../assets/icons/save.svg';

const Sidebar = memo(({ 
  chatHistory, 
  reports, 
  onChatSelect, 
  onDeleteChat, 
  isOpen, 
  openReportInNewTab, 
  onDownload, 
  setTooltip, 
  toggleSidebar 
}) => {
  const truncateFilename = (filename, maxLength = 25) => {
    if (filename.length <= maxLength) return filename;
    const extension = filename.split('.').pop();
    const nameWithoutExtension = filename.slice(0, -(extension.length + 1));
    const truncatedName = nameWithoutExtension.slice(0, maxLength - 3 - extension.length) + '...';
    return `${truncatedName}.${extension}`;
  };

  const handleMouseEnter = (event, text) => {
    const rect = event.target.getBoundingClientRect();
    setTooltip({ text, x: rect.right, y: rect.top });
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  const formatTitle = (title) => {
    if (!title) return "Unbenannter Chat";
    return title.length > 35 ? `${title.substring(0, 32)}...` : title;
};

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isOpen ? '←' : '→'}
      </button>
      <div className="sidebar-content">
        <div className="sidebar-section">
          <h2>Chatverlauf</h2>
          {chatHistory.length > 0 ? (
            <ul className="chat-history">
              {chatHistory.map((chat) => (
                <li key={chat.id} className="chat-history-item">
                  <span 
                  onClick={() => onChatSelect(chat.id)} 
                  title={chat.title} // This will show the full title on hover
                  >
                  {formatTitle(chat.title)}
                  </span>
                  <button 
                    className="delete-chat-btn" 
                    onClick={() => onDeleteChat(chat.id)}
                    onMouseEnter={(e) => handleMouseEnter(e, "Chat löschen")}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={trashIcon} alt="Delete" />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>Keine Chats vorhanden</p>
          )}
        </div>
        <div className="sidebar-section bibliothek">
          <h2 className="bibliothek-header">Bibliothek</h2>
          <div className="report-list-container">
            {reports.length > 0 ? (
              <ul className="report-list">
                {reports.map((report) => (
                  <li key={report.id} className="report-item">
                    <span className="report-name" title={report.name}>
                      {truncateFilename(report.name)}
                    </span>
                    <div className="report-actions">
                      {report.viewLink && (
                        <button 
                          className="report-action-btn" 
                          onClick={() => openReportInNewTab(report.viewLink)}
                          onMouseEnter={(e) => handleMouseEnter(e, "Bericht anzeigen")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img src={viewIcon} alt="View" />
                        </button>
                      )}
                      {report.downloadLink && (
                        <button 
                          className="report-action-btn" 
                          onClick={() => onDownload(report.downloadLink)}
                          onMouseEnter={(e) => handleMouseEnter(e, "Bericht herunterladen")}
                          onMouseLeave={handleMouseLeave}
                        >
                          <img src={saveIcon} alt="Save" />
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>Keine Berichte vorhanden</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;