import React, { useState } from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import logo from '../assets/logo512.png';

function AuthPage({ onLogin, onRegister }) {
  const [isLogin, setIsLogin] = useState(true);
  const [authError, setAuthError] = useState(null);

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setAuthError(null); // Clear any existing errors when switching forms
  };

  const handleRegisterSuccess = () => {
    setIsLogin(true);
    setAuthError(null);
  };

  const handleLoginAttempt = async (email, password) => {
    try {
      const result = await onLogin(email, password);
      if (result.error) {
        setAuthError(result.error);
      }
    } catch (error) {
      setAuthError('An unexpected error occurred. Please try again.');
    }
  };

  const handleRegisterAttempt = async (email, password) => {
    try {
      const result = await onRegister(email, password);
      if (result.error) {
        setAuthError(result.error);
      } else {
        handleRegisterSuccess();
      }
    } catch (error) {
      setAuthError('An unexpected error occurred during registration. Please try again.');
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-content">
        <img src={logo} alt="Neurix Logo" className="auth-logo" />
        <div className="auth-container">
          {authError && <div className="auth-error">{authError}</div>}
          {isLogin ? (
            <>
              <LoginForm onLoginSuccess={handleLoginAttempt} />
              <div className="auth-toggle">
                Don't have an account?
                <button type="button" onClick={toggleForm}>Register</button>
              </div>
            </>
          ) : (
            <>
              <RegisterForm 
                onRegisterSuccess={handleRegisterAttempt} 
              />
              <div className="auth-toggle">
                Already have an account?
                <button type="button" onClick={toggleForm}>Login</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AuthPage;