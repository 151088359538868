import React from 'react';
import Icon from './icon'; // Make sure this path is correct

const Button = ({ children, onClick, disabled, className, icon, tooltip, tooltipPosition = 'top' }) => (
  <div className={`tooltip tooltip-${tooltipPosition}`}>
    <button className={`button ${className || ''}`} onClick={onClick} disabled={disabled}>
      {icon && <Icon name={icon} className="button-icon" />}
      {children}
    </button>
    {tooltip && <span className="tooltiptext">{tooltip}</span>}
  </div>
);

export default Button;